import React from 'react';
import { Navbar, Nav, Button, Container, NavDropdown } from 'react-bootstrap';
import "./Header.css"
import logo from '../image/logo.png'; 

const Header = () => {
  return (
    <Navbar  className="navbar-custom {
">
      <Container> 
        <Navbar.Brand href="/">
          <img
            src={logo}  
            width="300"
            className="d-inline-block align-top"
            alt="Markaevi Logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link href="/">Anasayfa</Nav.Link>
            <Nav.Link href="/about">Biz Kimiz?</Nav.Link>
            <NavDropdown title="Neler Yapıyoruz?" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Option 1</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">Option 2</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/references">Referanslarımız</Nav.Link>
            <Nav.Link href="/blog">Blog</Nav.Link>
          </Nav>
          <Button variant="dark" className="contact-button">Bize Ulaşın</Button>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
