import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Questionnaire.css';

const questions = [
  { id: 1, question: 'Şirketinizin dijitalleşme konusunda belirlenmiş bir stratejisi var mı?' },
  { id: 2, question: 'Üst yönetim dijitalleşmeye ne kadar önem veriyor ve destekliyor?' },
  { id: 3, question: 'Bulut tabanlı çözümler kullanıyor musunuz?' },
  { id: 4, question: 'Veri analizi ve büyük veri teknolojileri kullanılıyor mu?' },
  { id: 5, question: 'Güçlü siber güvenlik önlemleri alınmış mı?' },
  { id: 6, question: 'İş süreçlerinde otomasyon ne kadar yaygın?' },
  { id: 7, question: 'Günlük iş operasyonlarında dijital araçlar ve yazılımlar kullanılıyor mu?' },
  { id: 8, question: 'Şirket hızlı bir şekilde değişikliklere uyum sağlayabiliyor mu?' },
  { id: 9, question: 'Web sitesi, mobil uygulama ve sosyal medya gibi dijital platformlarda varlık gösteriliyor mu?' },
  { id: 10, question: 'Dijital CRM sistemleri kullanılıyor mu?' },
  { id: 11, question: 'Dijital pazarlama stratejileri ve araçları etkin şekilde kullanılıyor mu?' },
  { id: 12, question: 'Çalışanların dijital yetkinlikleri yeterli mi?' },
  { id: 13, question: 'Dijital becerileri geliştirmeye yönelik sürekli eğitim programları mevcut mu?' },
  { id: 14, question: 'Dijital dönüşümü destekleyen bir şirket kültürü var mı?' },
  { id: 15, question: 'Yapay zeka, IoT, blok zinciri gibi yeni teknolojiler deneniyor mu?' },
  { id: 16, question: 'Araştırma ve geliştirme faaliyetlerine yeterince yatırım yapılıyor mu?' },
];

const QUESTIONS_PER_PAGE = 2;

const Questionnaire = ({ setTotalScore }) => {
  const [answers, setAnswers] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const navigate = useNavigate();

  const handleOptionChange = (questionId, score) => {
    setAnswers({ ...answers, [questionId]: score });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const totalScore = Object.values(answers).reduce((sum, score) => sum + score, 0);
    setTotalScore(totalScore);
    navigate('/results');
  };

  const handleNext = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePrev = () => {
    setCurrentPage(currentPage - 1);
  };

  const startIndex = currentPage * QUESTIONS_PER_PAGE;
  const endIndex = startIndex + QUESTIONS_PER_PAGE;
  const currentQuestions = questions.slice(startIndex, endIndex);

  return (
    <div className="questionnaire">
      <form onSubmit={handleSubmit}>
        {currentQuestions.map((q) => (
          <div key={q.id} className="question-card">
            <h5>{q.question}</h5>
            <div className="options">
              {Array.from({ length: 11 }, (_, index) => 10 - index).map((score) => (
                <label key={score}>
                  {score}
                  <input
                    type="radio"
                    name={`question-${q.id}`}
                    value={score}
                    checked={answers[q.id] === score}
                    onChange={() => handleOptionChange(q.id, score)}
                  />
                </label>
              ))}
            </div>
          </div>
        ))}
        <div className="navigation-buttons">
          {currentPage > 0 && (
            <button type="button" className="button" onClick={handlePrev}>
              Önceki
            </button>
          )}
          {endIndex < questions.length && (
            <button type="button" className="button" onClick={handleNext}>
              Sonraki
            </button>
          )}
          {endIndex >= questions.length && (
            <button type="submit" className="button">
              Sonuçları Göster
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default Questionnaire;
