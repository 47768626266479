import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Header';
import Questionnaire from './components/Questionnaire';
import Results from './components/Results';
import Footer from './components/Footer';
import './App.css';

const App = () => {
  const [totalScore, setTotalScore] = useState(0);

  return (
    <Router>
      <div className="App">
        <Navbar />
        <main>
          <Routes>
            <Route path="/" element={<Questionnaire setTotalScore={setTotalScore} />} />
            <Route path="/results" element={<Results totalScore={totalScore} />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
