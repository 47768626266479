import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Results.css';

const Results = ({ totalScore }) => {
  const navigate = useNavigate();

  const totalPossibleScore = 160; // 16 soru, her biri 10 puan
  const percentage = (totalScore / totalPossibleScore) * 100;

  let category = '';
  if (percentage <= 20) {
    category = 'Dijitalleşme yolundasınız';
  } else if (percentage <= 40) {
    category = 'İyileştirme yolundasınız';
  } else if (percentage <= 60) {
    category = 'Geliştirme yolundasınız';
  } else if (percentage <= 80) {
    category = 'Mükemmelleştirme yolundasınız';
  } else {
    category = 'Dijitalde öncüsünüz';
  }

  return (
    <div className="results">
      <h1>Sonuçlar</h1>
      <p>Toplam Puan: {totalScore} / {totalPossibleScore}</p>
      <p>Yüzde: {percentage.toFixed(2)}%</p>
      <p>Kategori: {category}</p>
      <button className="button" onClick={() => navigate('/')}>Yeniden Başla</button>
    </div>
  );
};

export default Results;
