import React from "react";
import "./Footer.css";
import logo from "../image/Markaevilogo.png";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="column">
        <img src={logo} alt="Markaevi Logo" width="300" />
        <br/>
        <p>
          19 Mayıs Cad. Turhan İş Merkezi
          <br />
          No. 25 D.9 34360 Şişli/İstanbul
        </p>
        <p>
          info@markaevi.com.tr
          <br />
          +90 532 667 38 93
        </p>
      </div>
      <div className="column">
        <h3>Biz Kimiz?</h3>
        <p>
          <a href="#anasyafa">Anasayfa</a>
        </p>
        <p>
          <a href="#biz-kimiz">Biz Kimiz?</a>
        </p>
        <p>
          <a href="#neler-yapiyoruz">Neler Yapıyoruz?</a>
        </p>
        <p>
          <a href="#referanslarimiz">Referanslarımız</a>
        </p>
        <p>
          <a href="#blog">Blog</a>
        </p>
        <p>
          <a href="#iletisim">İletişim</a>
        </p>
      </div>
      <div className="column">
        <h3>Neler Yapıyoruz?</h3>
        <p>
          <a href="#medya-planlama">Medya Planlama ve Satın Alma</a>
        </p>
        <p>
          <a href="#pr">PR</a>
        </p>
        <p>
          <a href="#reklam">Reklam</a>
        </p>
        <p>
          <a href="#strateji">Strateji</a>
        </p>
        <p>
          <a href="#web-tasarim">Web Tasarım & Yazılım</a>
        </p>
        <p>
          <a href="#e-ticaret">E-Ticaret</a>
        </p>
      </div>
      <div className="column">
        <h3>Bizi Takip Edin</h3>
        <p>
          <a href="https://facebook.com">Facebook</a>
        </p>
        <p>
          <a href="https://twitter.com">Twitter</a>
        </p>
        <p>
          <a href="https://linkedin.com">LinkedIn</a>
        </p>
        <p>
          <a href="https://instagram.com">Instagram</a>
        </p>
      </div>
      <div className="footer-bottom">© 2024 Markaevi • All rights reserved</div>
    </footer>
  );
};

export default Footer;
